const inView = require('in-view');

export class HeadingAnimation {
  private timeout: ReturnType<typeof setTimeout>;
  private animateTopLine: boolean = false;
  private animateBottomLine: boolean = false;

  constructor(public el: HTMLElement) {
    if (this.el) {
      this.init();
    }
  }

  init(): void {
    this.prepareHeading();
    this.setupTopLine();
    this.setupContainerBottomLine();
    this.setupInView();
  }

  setupInView(): void {
    const id: string = this.el.dataset.inViewId;
    inView.offset(125);
    if (id) {
      inView(`[data-in-view-id="${id}"]`).once('enter', (): void => {
        this.typeWriter();
        console.log('in view');
      });
    }
  }

  prepareHeading(): void {
    const text: string = this.el.innerText;
    if (text) {
      const newText: string = text
        .split('')
        .map((letter: string) => `<span style="opacity: 0">${letter}</span>`)
        .join('');
      this.el.innerHTML = newText;
    }
  }

  typeWriter(): void {
    const spans: NodeListOf<HTMLElement> = this.el.querySelectorAll('span');
    let i = 0;
    const speed = 100; /* The speed/duration of the effect in milliseconds */

    clearTimeout(this.timeout);

    const typeWriter = (): void => {
      if (i < spans.length) {
        spans[i].style.opacity = '1';
        i = i + 1;
        this.timeout = setTimeout(typeWriter, speed);
      } else {
        this.showTopLine();
        this.showBottomLine();
      }
    };
    typeWriter();
  }

  setupTopLine(): void {
    const heading: Element = this.el.closest('.heading--hasTopLine');
    if (heading) {
      heading.classList.add('heading--hideTopLine');
      this.animateTopLine = true;
    }
  }

  setupContainerBottomLine(): void {
    const container: Element = this.el.closest('.heading__container');
    if (container) {
      container.classList.add('heading__container--hideBottomLine');
      this.animateBottomLine = true;
    }
  }

  showTopLine(): void {
    const heading: Element = this.el.closest('.heading--hasTopLine');
    if (heading) {
      heading.classList.remove('heading--hideTopLine');
    }
  }

  showBottomLine(): void {
    if (this.animateBottomLine) {
      this.el
        .closest('.heading__container')
        .classList.remove('heading__container--hideBottomLine');
    }
  }
}
