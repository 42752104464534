import { debounce } from 'ts-debounce';

import '../scss/style.scss';
import { GallerySwiper } from './components/GallerySwiper';
import { DropdownExpandable } from './components/DropdownExpandable';
import { Header } from './components/Header';
import { VideoPlayer } from './components/VideoPlayer';
import { Map } from './components/Map';
import { SideBar } from './components/SideBar';
import { ScrollTo } from './components/ScrollTo';
import { FeaturesSwiper } from './components/FeaturesSwiper';
import { SmartSolutions } from './components/SmartSolutions';
import { ScrollController } from './components/ScrollController';
import { MoreNews } from './components/MoreNews';
import { MoreTrends } from './components/MoreTrends';
import { Cookie } from './components/Cookie';
import { HeadingAnimation } from './components/HeadingAnimation';

let scrollController: ScrollController;

(function() {
  if (typeof (NodeList as any).prototype.forEach !== 'function') {
    (NodeList as any).prototype.forEach = Array.prototype.forEach;
  }

  return false;
})();

const onLoad = (e: Event) => {
  inits();
  onResize(e);
};

const onResize = (e: Event) => {
  scrollController.update();

  onScroll(e);
};

const debounceScroll = debounce((): void => {
  if (scrollController) {
    scrollController.onScroll();
  }
}, 10);

const onScroll = (e: Event) => {
  debounceScroll();
};

const inits = () => {
  const trendsSwiper: GallerySwiper = new GallerySwiper(
    '.gallery-swiper.gallery-swiper--onlyDesktop',
    {
      pagination: {
        el: '.swiper-pagination',
        type: 'progressbar'
      }
    },
    true
  );

  const gallerySwiper: GallerySwiper = new GallerySwiper(
    '.js-gallery-swiper',
    {
      speed: 800,
      pagination: {
        el: '.swiper-pagination',
        type: 'progressbar'
      },
      navigation: {
        nextEl: '.more-projects__nav--next',
        prevEl: '.more-projects__nav--prev'
      }
    },
    true
  );

  const projectsSwiper: GallerySwiper = new GallerySwiper(
    '.more-projects .swiper-container',
    {
      speed: 1000,
      loop: true,
      slidesPerView: 1,

      navigation: {
        nextEl: '.more-projects__nav--next',
        prevEl: '.more-projects__nav--prev'
      },

      breakpoints: {
        768: {
          slidesPerView: 4
        }
      }
    },
    true
  );
  const headerDropdownExpandable = new DropdownExpandable(
    '.header .dropdown-expandable'
  );
  const header = new Header('.header');
  document
    .querySelectorAll('.js-video-player')
    .forEach((player: HTMLElement): void => {
      new VideoPlayer(player);
    });

  const googleMap = new Map('#map');
  const sideBar = new SideBar('#main-content');
  const scrollTo = new ScrollTo('[data-scroll-to-id]');
  const featuresSwiper = new FeaturesSwiper('.js-features');
  const smartSolutions = new SmartSolutions();
  scrollController = new ScrollController();
  const moreNews = new MoreNews(
    '.news-list__container',
    '.news-list .button--more',
    (): void => {
      scrollController.update();
    }
  );
  const moreTrends = new MoreTrends(
    '.gallery-swiper--onlyMobile .gallery-swiper__container',
    '.gallery-swiper__show-more .show-more'
  );
  const cookie = new Cookie('.cookie');
  document
    .querySelectorAll('.js-animate-heading')
    .forEach((heading: HTMLElement) => {
      new HeadingAnimation(heading);
    });
};

window.addEventListener('load', onLoad);
window.addEventListener('resize', onResize);
window.addEventListener('scroll', onScroll);
