import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { Pagination } from '../interfaces/interfaces';

interface ApiData {
  requestUrl: string;
}

declare var apiData: ApiData;

export abstract class More<T extends Pagination> {
  private moreButton: HTMLElement;
  private nextPage: number = 2;
  private totPages: number = 999;
  private container: HTMLElement;

  constructor(
    container: string,
    button: string,
    public action: string,
    public cb?: () => void
  ) {
    this.moreButton = document.querySelector(button);
    this.container = document.querySelector(container);
    if (this.moreButton && this.container) {
      this.init();
    }
  }

  abstract getTemplate(data: T): string;

  private init(): void {
    this.moreButton.addEventListener('click', this.fetchApi.bind(this));
  }

  private updatePagination(resp: Pagination): void {
    this.nextPage = parseInt(resp.pagination.next_page, 10);
    this.totPages = parseInt(resp.pagination.total_pages, 10);

    if (this.nextPage >= this.totPages) {
      this.moreButton.classList.add('button--disabled');
    }
  }

  private render(data: T) {
    const html = this.getTemplate(data);
    this.container.insertAdjacentHTML('beforeend', html);
  }

  private fetchApi(e: Event): void {
    e.preventDefault();

    const config: AxiosRequestConfig = {
      method: 'post',
      url: apiData.requestUrl,
      params: {
        // action: 'get-more-news',
        action: this.action,
        next_page: this.nextPage
      }
    };

    axios(config)
      .then((response: AxiosResponse<T>) => {
        const data = response.data;
        if (data) {
          this.updatePagination(data);
          this.render(data);
          if (this.cb) this.cb();
        }
      })
      .catch((error: AxiosResponse) => {
        console.log(error);
      });
  }
}
