import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { VideoPlayer } from './VideoPlayer';

export class Modal {
  private open: boolean = false;
  public closeBtn: HTMLElement;
  // private b: HTMLBodyElement = document.getElementsByTagName('BODY');
  private modal: HTMLElement;
  private videos: VideoPlayer[] = [];

  constructor(public openBtn: HTMLElement, public lockBody: boolean = true) {
    this.modal = document.getElementById(this.openBtn.dataset.modal);
    if (this.modal) {
      this.closeBtn = this.modal.querySelector('.js-close-modal');
      this.init();
    }
  }

  get openStatus(): boolean {
    return this.open;
  }

  init(): void {
    this.openBtn.addEventListener('click', this.openModal.bind(this));
    this.closeBtn.addEventListener('click', this.closeModal.bind(this));
    document.addEventListener('keyup', this.keyEvents.bind(this));

    //  If modal contains video, Init video player
    this.initVideo();
  }

  keyEvents(e: KeyboardEvent): void {
    if (e.key === 'Escape' && this.closeBtn) {
      this.closeBtn.click();
    }
  }

  openModal(e: Event): boolean {
    e.preventDefault();

    if (this.modal) {
      if (!this.open) {
        if (this.lockBody) disableBodyScroll(this.modal);
        this.modal.classList.add('modal--show');

        this.open = true;
      } else {
        this.closeModal();
      }
    }

    return false;
  }

  closeModal(e?: Event): boolean {
    if (e) e.preventDefault();
    this.modal.classList.remove('modal--show');
    this.pauseModalVideo();
    this.open = false;
    if (this.lockBody) enableBodyScroll(this.modal);

    return false;
  }

  initVideo(): void {
    this.modal
      .querySelectorAll('.js-modal-video')
      .forEach((player: HTMLElement): void => {
        const videoPlayer = new VideoPlayer(player);
        this.videos.push(videoPlayer);
      });
  }

  pauseModalVideo(): void {
    this.videos.forEach((videoPlayer: VideoPlayer): void => {
      videoPlayer.pause();
    });
  }
}
