import { DropdownExpandable } from './DropdownExpandable';
import { Modal } from './Modal';

export class SmartSolutions {
  private el: HTMLElement;

  private modals: Modal[] = [];
  private dropdownMenu: DropdownExpandable;

  constructor() {
    this.el = document.querySelector('.js-smart-solutions');
    if (this.el) this.init();
  }

  init(): void {
    this.initDropdown();
    this.initModals();
    this.initSlider();
  }

  private initDropdown(): void {
    this.dropdownMenu = new DropdownExpandable(
      '.smart-solutions .dropdown-expandable'
    );
  }

  private initModals(): void {
    const modalNodeList = this.el.querySelectorAll('.js-solutions-modal');

    modalNodeList.forEach((openBtn: HTMLElement): void => {
      const modal = new Modal(openBtn);
      this.modals.push(modal);
    });
  }

  private initSlider(): void {
    const switchButtons = this.el.querySelectorAll('[data-show-solution]');
    if (switchButtons) {
      switchButtons.forEach((button: HTMLElement): void => {
        button.addEventListener('click', this.toggleActive.bind(this));
      });
    }
  }

  private toggleActive(e: Event): void {
    const el = e.target as HTMLElement;
    const id: string = el.dataset.showSolution;

    if (id) {
      this.hideImageWraps(id);
      const imgWrap = this.el.querySelector(`[data-solution-id="${id}"]`);
      if (imgWrap) imgWrap.classList.toggle('isVisible');
    }
  }

  private hideImageWraps(exceptionID: string): void {
    const all = this.el.querySelectorAll(
      `[data-solution-id]:not([data-solution-id="${exceptionID}"])`
    );
    all.forEach((wrap: HTMLElement): void => {
      wrap.classList.remove('isVisible');
    });
  }
}
