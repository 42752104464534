export class ScrollController {
  private body: HTMLElement = document.body;
  private html: HTMLElement = document.documentElement;
  private height: number;
  private previosY: number = window.pageYOffset;

  constructor() {
    this.height = this.getHeight();
  }

  private getHeight(): number {
    return Math.max(this.body.scrollHeight, this.body.offsetHeight, this.html.clientHeight, this.html.scrollHeight, this.html.offsetHeight);
  }

  public update(): void {
    this.height = this.getHeight();
  }

  public onScroll() {
    this.stickyVisibilityToggler();
    this.headerVisibilityToggler();
  }

  private stickyVisibilityToggler(): void {
    const offset = window.innerHeight * 1.35;
    const y = window.pageYOffset + offset;

    if (y >= this.height) {
      this.body.classList.add('sticky-scroll-down--isHidden');
    } else {
      this.body.classList.remove('sticky-scroll-down--isHidden');
    }
  }

  private headerVisibilityToggler(): void {
    const y = window.pageYOffset;
    if (y > 100 && this.scrollDown(y)) {
      this.hideMenu();
    }
    if (!this.scrollDown(y)) {
      this.showMenu();
    }
    this.previosY = y;
  }

  scrollDown(y: number): boolean {
    return y - this.previosY > 0 ? true : false;
  }

  private hideMenu(): void {
    this.body.classList.add('header--isHidden');
  }

  private showMenu(): void {
    this.body.classList.remove('header--isHidden');
  }
}
