import { Pagination, SingleNew } from '../interfaces/interfaces';
import { More } from './More';

interface News extends Pagination {
  news: SingleNew[];
}

export class MoreNews extends More<News> {
  constructor(container: string, button: string, public cb?: () => void) {
    super(container, button, 'get-more-news', cb);
  }

  getTemplate(d: News): string {
    const data = d.news;
    const html = data.map(
      (n): string => `
       <article class="trends-slide trends-slide--animate-load">
            <div class="trends-slide__text">
              <div class="text-block">
                <h3>${n.title}</h3>
              </div>
              <div class="text-block text-block--s">
                <p>${n.excerpt}</p>
              </div>
            </div>
            <div class="trends-slide__image">
              <a href="${n.url}" class="button">sužinokite daugiau</a>
              <img src="${n.img}" alt="${n.img_alt}">
            </div>
          </article>`
    );

    return html.join('');
  }
}
