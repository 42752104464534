import swiper, { SwiperOptions } from 'swiper';
import { VideoPlayer } from './VideoPlayer';
import { CustomCursor } from './CustomCursor';
interface Slide {
  html: HTMLElement;
  hasVideo: boolean;
  video: VideoPlayer;
}

export class GallerySwiper {
  public cursor: CustomCursor;
  private swiper: swiper;
  private swiperSlides: Slide[] = [];

  constructor(
    selector: string,
    public options: SwiperOptions,
    public customCursor: boolean = false
  ) {
    const container: HTMLElement = document.querySelector(selector);
    if (container) this.init(container);
  }

  init(container: HTMLElement) {
    this.swiper = new swiper(container, this.options);

    if (this.swiper.slides.length) {
      this.initVirtualSlides();
    }

    this.swiper.on('slideChange', () => {
      if (
        this.swiper.previousIndex &&
        this.swiperSlides[this.swiper.previousIndex].hasVideo
      ) {
        this.swiperSlides[this.swiper.previousIndex].video.pause();
      }
    });

    if (this.customCursor) {
      this.initCustomCursor(container);
    }
  }

  initVirtualSlides(): void {
    Array.from(this.swiper.slides).forEach((slide: HTMLElement): void => {
      const videoPlayer = slide.querySelector(
        '.js-gallery-video'
      ) as HTMLElement;
      const sl: Slide = {
        html: slide,
        hasVideo: videoPlayer ? true : false,
        video: new VideoPlayer(videoPlayer)
      };
      this.swiperSlides.push(sl);
    });
  }

  initCustomCursor(container: HTMLElement): void {
    this.cursor = new CustomCursor(container, '.drag-slide-cursor');
  }
}
