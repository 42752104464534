import swiper, { SwiperOptions } from 'swiper';
import { VideoPlayer } from './VideoPlayer';
import { Modal } from './Modal';

const inView = require('in-view');

export class FeaturesSwiper {
  private el: HTMLElement;
  private swiper: swiper;
  private videos: VideoPlayer[] = [];
  private modals: Modal[] = [];
  private modalOpen: boolean = false;
  private slideTitle: HTMLElement;
  private timeout: ReturnType<typeof setTimeout>;

  constructor(public selector: string) {
    this.el = document.querySelector(this.selector);
    if (this.el) {
      this.initSlideTitle();
      this.initSwiper();
      this.initVideo();
      this.initModals();
      this.initInView();
      this.events();
    }
  }

  events(): void {
    this.videos.forEach((videoPlayer: VideoPlayer): void => {
      videoPlayer.video.addEventListener('ended', this.onVideoEnd.bind(this));
    });
  }

  initModals(): void {
    const modals: NodeListOf<HTMLElement> = this.el.querySelectorAll(
      '.js-feature-modal'
    );
    modals.forEach((modal: HTMLElement): void => {
      modal.addEventListener('click', this.onModalOpen.bind(this));
      const modalInstance: Modal = new Modal(modal, false);
      modalInstance.closeBtn.addEventListener(
        'click',
        this.onModalClose.bind(this)
      );
      this.modals.push(modalInstance);
    });
  }

  onModalClose(): void {
    this.videos[this.swiper.activeIndex].play();
    this.modalOpen = false;
    this.toggleModalOpenClass(this.modalOpen);
  }

  onModalOpen(): void {
    this.videos[this.swiper.activeIndex].pause();
    this.modalOpen = true;
    this.toggleModalOpenClass(this.modalOpen);
    if (this.modals[this.swiper.activeIndex].openStatus) {
      this.modalOpen = false;
      this.toggleModalOpenClass(this.modalOpen);
      this.videos[this.swiper.activeIndex].play();
    }
  }

  toggleModalOpenClass(isOpen: boolean): void {
    if (isOpen) {
      this.swiper.slides[this.swiper.activeIndex].classList.add(
        'features--modal-open'
      );
    } else {
      this.swiper.slides[this.swiper.activeIndex].classList.remove(
        'features--modal-open'
      );
    }
  }

  initVideo(): void {
    const videos = this.el.querySelectorAll('.video-payer');
    videos.forEach((video: HTMLElement): void => {
      const player = new VideoPlayer(video, false);
      this.videos.push(player);
    });
  }

  onVideoEnd() {
    this.swiper.slideNext();
  }

  initInView(): void {
    inView.threshold(0.4);
    inView(this.selector)
      .on('enter', () => {
        if (!this.modalOpen) this.videos[this.swiper.activeIndex].play();
      })
      .on('exit', () => {
        this.videos[this.swiper.activeIndex].pause();
      });
  }

  nextSlide(): void {}

  initSwiper(): void {
    const options: SwiperOptions = {
      loop: true,

      slidesPerView: 1,
      effect: 'fade',

      pagination: {
        el: '#features-pagination',
        clickable: true,
        bulletClass: 'features__swiper-bullet',
        bulletActiveClass: 'active',
        renderBullet: (index: number, className: string): string => {
          const slideNo = index + 1;
          return `<li class="${className}"><span>${
            slideNo < 10 ? `0${slideNo}` : slideNo
          }</span></li>`;
        }
      },

      // Navigation arrows
      navigation: {
        nextEl: '.features__navigation--next',
        prevEl: '.features__navigation--prev'
      },
      on: {
        slideChange: (): void => {
          this.videos[this.swiper.previousIndex].stop();
          this.videos[this.swiper.activeIndex].play();
          this.modals[this.swiper.previousIndex].closeModal();
          this.updateTitle();
        }
      }
    };
    this.swiper = new swiper('.features__swiper', options);
  }

  initSlideTitle(): void {
    this.slideTitle = this.el.querySelector('#features-slide-title');
  }

  updateTitle(): void {
    const newTitle: string = this.swiper.slides[this.swiper.activeIndex].dataset
      .slideTitle;

    if (this.slideTitle) {
      this.typeWriter(newTitle, this.slideTitle);
    }
  }

  typeWriter(txt: string, el: HTMLElement): void {
    this.slideTitle.innerHTML = '';
    let i = 0;
    const speed = 50; /* The speed/duration of the effect in milliseconds */

    clearTimeout(this.timeout);

    const typeWriter = (): void => {
      if (i < txt.length) {
        el.innerHTML += txt.charAt(i);
        i = i + 1;
        this.timeout = setTimeout(typeWriter, speed);
      }
    };
    typeWriter();
  }
}
