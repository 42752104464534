export class VideoPlayer {
  private player: HTMLElement;
  public video: HTMLVideoElement;
  private playButton: HTMLElement;

  constructor(
    selector: string | HTMLElement,
    public showControl: boolean = false
  ) {
    if (typeof selector === 'string') {
      this.player = document.querySelector(selector);
    } else {
      this.player = selector;
    }

    if (this.player) {
      this.video = this.player.querySelector('video');
      this.playButton = this.player.querySelector('.video-payer__play');
      this.init();
    }
  }

  init(): void {
    if (this.playButton && this.video) {
      this.playButton.addEventListener('click', this.play.bind(this));
      this.video.addEventListener('play', this.videoPlaying.bind(this));
      this.video.addEventListener('pause', this.videoPaused.bind(this));
      this.video.addEventListener('ended', this.videoEnded.bind(this));
    }
  }
  videoPlaying(): void {
    this.player.classList.add('isPlaying');
    this.video.controls = true;
  }

  videoPaused(): void {
    this.player.classList.remove('isPlaying');
    this.video.controls = false;
  }

  play(e?: Event): void {
    if (e) {
      e.preventDefault();
    }
    this.video.play();
  }
  stop() {
    this.video.pause();
    this.video.currentTime = 0;
  }
  pause() {
    this.video.pause();
  }
  videoEnded(): boolean {
    this.video.currentTime = 0;
    return true;
  }
}
