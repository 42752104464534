declare var animateScrollTo: any;
require('animated-scroll-to');

export class ScrollTo {
  private buttonNodes: NodeListOf<HTMLElement>;

  constructor(selector: string) {
    this.buttonNodes = document.querySelectorAll(selector);

    if (this.buttonNodes) {
      this.init();
    }
  }

  init() {
    this.buttonNodes.forEach((node: HTMLElement): void => node.addEventListener('click', this.clickDispatcher.bind(this)));
  }

  clickDispatcher(e: Event) {
    e.preventDefault();
    const to: string = (e.target as HTMLElement).closest('a').dataset.scrollToId;
    if (to) {
      const toElement: HTMLElement = document.getElementById(to);
      const offset: number = window.innerHeight * 0.025;

      if (toElement) {
        const top: number = toElement.offsetTop - offset;
        animateScrollTo(top);
      }
    }
  }
}
