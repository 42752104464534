export class Cookie {
  private cookie: HTMLElement;
  private agreeBtn: HTMLElement;

  constructor(selector: string) {
    this.cookie = document.querySelector('.cookie');
    if (this.cookie) {
      this.agreeBtn = this.cookie.querySelector('.js-set-cookie');
      this.init();
    }
  }

  init(): void {
    this.agreeBtn.addEventListener('click', this.agreePolicy.bind(this));
  }

  closeCookie(e?: Event): void {
    if (e) e.preventDefault();
    this.cookie.style.opacity = '0';
    this.cookie.style.pointerEvents = 'none';
    setTimeout(() => {
      this.cookie.style.display = 'none';
    }, 250);
  }

  agreePolicy(e: Event): void {
    e.preventDefault();
    this.setCookie('policy', 'agree', 365);
    this.closeCookie();
  }

  setCookie(name: string, value: string, days: number): void {
    let expires;
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = `expires=${date.toUTCString()}`;
    } else {
      expires = '';
    }
    document.cookie = `${encodeURIComponent(name)}=${encodeURIComponent(value)}${expires}; path=/`;
    this.cookie.dataset.expires = expires;
  }
}
