/* global google */
declare var google: any;

const createHTMLMapMarker = ({ OverlayView = google.maps.OverlayView, ...args }) => {
  class HTMLMapMarker extends OverlayView {
    public latlng: google.maps.LatLng;
    public html: string;
    public div: HTMLElement;

    constructor() {
      super();
      this.latlng = args.latlng;
      this.html = args.html;
      this.setMap(args.map);
    }

    createDiv(): void {
      this.div = document.createElement('div');
      this.div.style.position = 'absolute';
      if (this.html) {
        this.div.innerHTML = this.html;
      }
    }

    appendDivToOverlay(): void {
      const panes = this.getPanes();
      (panes as any).overlayImage.appendChild(this.div);
    }

    positionDiv(): void {
      const point = this.getProjection().fromLatLngToDivPixel(this.latlng);
      const offset = 25;
      if (point) {
        this.div.style.left = `${point.x - offset}px`;
        this.div.style.top = `${point.y - offset}px`;
      }
    }

    draw(): void {
      if (!this.div) {
        this.createDiv();
        this.appendDivToOverlay();
      }
      this.positionDiv();
    }

    remove(): void {
      if (this.div) {
        this.div.parentNode.removeChild(this.div);
        this.div = null;
      }
    }

    getPosition(): google.maps.LatLng {
      return this.latlng;
    }

    getDraggable(): boolean {
      return false;
    }
  }

  return new HTMLMapMarker();
};

export default createHTMLMapMarker;
