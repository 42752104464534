import { slideToggle } from 'slidetoggle';

export class DropdownExpandable {
  private dropdownLists: NodeListOf<HTMLUListElement>;

  constructor(listSelector: string) {
    this.dropdownLists = document.querySelectorAll(listSelector);

    if (this.dropdownLists) {
      this.init();
    }
  }

  init(): void {
    this.dropdownLists.forEach((list: HTMLUListElement): void => {
      const li: NodeListOf<HTMLElement> = list.querySelectorAll('.js-toggle-btn');
      if (li) {
        li.forEach((el: HTMLElement): void => {
          el.addEventListener('click', (evt: Event) => {
            const target = evt.target as HTMLElement;
            const targetLi: HTMLElement = target.closest('li');

            if (!targetLi.classList.contains('isOpen')) {
              this.closeAll(target.closest('ul'));
              targetLi.classList.add('isOpen');
              const toggleBlock = targetLi.querySelector('.js-toggle-content') as HTMLElement;
              slideToggle.slideToggle(toggleBlock, 500);
            } else {
              this.closeAll(target.closest('ul'));
            }
          });
        });
      }
    });
  }

  closeActive(list: HTMLUListElement): void {
    const activeBlock = list.querySelector('.isOpen .js-toggle-content') as HTMLElement;
    if (activeBlock) {
      slideToggle.slideToggle(activeBlock, 500);
      (activeBlock.parentNode as HTMLElement).classList.remove('isOpen');
    }
  }

  closeAll(list: HTMLUListElement): void {
    const li: NodeListOf<HTMLElement> = list.querySelectorAll('li');
    if (li) {
      li.forEach((el: HTMLElement): void => {
        if (el.classList.contains('isOpen')) {
          el.classList.remove('isOpen');
          const activeBlock = el.querySelector('.js-toggle-content') as HTMLElement;
          slideToggle.slideToggle(activeBlock, 500);
        }
      });
    }
  }
}
