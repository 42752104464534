import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

export class Header {
  private header: HTMLElement;
  private burgerBtn: NodeListOf<HTMLElement>;
  private body: HTMLBodyElement = document.querySelector("body");
  private isMenuOpen: boolean = false;
  private navigation: HTMLElement;

  constructor(selector: string) {
    this.header = document.querySelector(selector);
    if (this.header) {
      this.init();
    }
  }

  init(): void {
    this.setupNavigation();
  }

  setupNavigation(): void {
    this.burgerBtn = this.header.querySelectorAll(".js-toggle-nav");
    this.navigation = this.header.querySelector(".nav-menu");

    if (this.burgerBtn && this.navigation) {
      this.burgerBtn.forEach((button: HTMLElement): void => {
        button.addEventListener("click", this.toggleNavMenu.bind(this));
      });

      setTimeout(() => {
        this.navigation.addEventListener(
          "transitionend",
          this.navigationTransitioned.bind(this)
        );
      }, 500);
    }
  }

  navigationTransitioned(): void {
    if (this.isMenuOpen) {
      this.navigation.classList.remove("nav-menu--close-animation");
    } else {
      this.navigation.classList.add("nav-menu--close-animation");
    }
  }

  toggleNavMenu(e: Event): void {
    e.preventDefault();
    this.body.classList.toggle("nav-menu--open");
    this.isMenuOpen = !this.isMenuOpen;
    if (this.isMenuOpen) {
      disableBodyScroll(this.navigation);
    } else {
      enableBodyScroll(this.navigation);
    }
  }
}
