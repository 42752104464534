import { debounce } from 'ts-debounce';

export class CustomCursor {
  private cursor: HTMLElement;
  private showCursor: boolean = false;
  private body: HTMLElement = document.body;

  constructor(public container: HTMLElement, cursorSelector: string) {
    this.cursor = document.querySelector(cursorSelector);

    if (this.cursor) {
      this.init();
    }
  }

  init(): void {
    window.addEventListener(
      'mousemove',
      debounce(this.onMouseMove.bind(this), 3)
    );
    this.container.addEventListener('mouseenter', this.onMouseEnter.bind(this));
    this.container.addEventListener('mouseleave', this.onMouseLeave.bind(this));
    this.container.addEventListener('mousedown', this.onMouseDown.bind(this));
    this.container.addEventListener('mouseup', this.onMouseUp.bind(this));

    const buttons: NodeListOf<HTMLElement> = this.container.querySelectorAll(
      '.js-hide-custom-cursor'
    );
    if (buttons) {
      buttons.forEach((button: HTMLElement): void => {
        button.addEventListener('mouseenter', (): void => {
          this.hideCursor();
          this.enableCursor(button);
        });
        button.addEventListener('mouseleave', (): void => {
          this.visibleCursor();
          this.disableCursor(button);
        });
      });
    }
  }

  onMouseDown(): void {
    this.body.classList.add('drag-slide-cursor--mouseDown');
  }

  onMouseUp(): void {
    this.body.classList.remove('drag-slide-cursor--mouseDown');
  }

  onMouseMove(event: MouseEvent): void {
    if (this.showCursor) {
      const x = event.clientX;
      const y = event.clientY;
      this.cursor.style.left = `${x}px`;
      this.cursor.style.top = `${y}px`;
    }
  }

  onMouseEnter(): void {
    this.visibleCursor();
    console.log('enter');
  }

  onMouseLeave(): void {
    this.hideCursor();
    console.log('leave');
  }

  hideCursor(): void {
    this.cursor.classList.remove('isVisible');
    this.showCursor = false;
  }

  visibleCursor(): void {
    this.cursor.classList.add('isVisible');
    this.showCursor = true;
  }

  enableCursor(el: HTMLElement): void {
    el.style.cursor = 'pointer';
  }

  disableCursor(el: HTMLElement): void {
    el.style.cursor = 'none';
  }
}
