declare var StickySidebar: any;
require('sticky-sidebar/dist/sticky-sidebar');

export class SideBar {
  private sidebarContainer: HTMLElement;

  constructor(selector: string) {
    this.sidebarContainer = document.querySelector(selector);
    if (this.sidebarContainer) {
      this.init();
    }
  }

  init(): void {
    const sidebar = new StickySidebar('#sidebar', {
      containerSelector: '#main-content',
      innerWrapperSelector: '.partners__sidebar-inner',
      topSpacing: 0,
      bottomSpacing: 0,
    });
  }
}
